"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const round_to_1 = require("round-to");
const action_1 = require("@src/app/services/action");
class RoundingcalculatorCtrl {
    constructor($scope, actionService) {
        this.$scope = $scope;
        this.actionService = actionService;
        this.round = 0;
    }
    $onInit() {
    }
    submitForm($form) {
        if ($form.$valid && (this.value != undefined)) {
            this.result = (0, round_to_1.roundTo)(this.value, this.round);
        }
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.value = undefined;
        this.result = undefined;
    }
    setRound(round) {
        this.result = undefined;
        this.round = round;
    }
    copy(id) {
        if (this.result != undefined)
            this.actionService.copyFactory(document.getElementById(id), this.result.toString());
    }
}
RoundingcalculatorCtrl.$inject = ['$scope', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameRoundingcalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: RoundingcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('roundingcalculator/');
    }]);
